export class CameraManager{  

    constructor(appManager, targetCamera){
        this.camera;
        this.appManager = appManager;
        this.highlightLayer;
        this.highlightLayer2;
        this.sky;
        this.target = targetCamera;
        this.hasSwiped = false;

        this.createCamera();
        this.createLights();
    }

    createCamera(){
        this.highlightLayer2 = new BABYLON.HighlightLayer("hl2", this.appManager.scene,{
            mainTextureRatio: 2
        });
        this.highlightLayer2.blurHorizontalSize = 2;
        this.highlightLayer2.blurVerticalSize = 2;

        this.highlightLayer = new BABYLON.HighlightLayer("hl1", this.appManager.scene,{
            mainTextureRatio: 1,
            mainTextureFixedSize: 2048,
            blurTextureSizeRatio: 1,
            blurVerticalSize: 4,
            blurHorizontalSize: 4,
            threshold: .025,
            isStroke: true
        });
        this.highlightLayer.blurHorizontalSize = 0.2;
        this.highlightLayer.blurVerticalSize = 0.2;
        this.highlightLayer.innerGlow = false;
        this.camera = new BABYLON.ArcRotateCamera("camera", 6, 1.3, 6, new BABYLON.Vector3(0,0,0), this.appManager.scene);
        this.camera.minZ = 0.01;
        this.camera.allowUpsideDown = false;
        this.camera.wheelPrecision = 150;
        this.camera.pinchDeltaPercentage = 0.0001;
        this.camera.useNaturalPinchZoom = false;
        this.camera.pinchPrecision = 0.0001;
        this.camera.panningSensibility = 0;
        this.camera.rebuildAnglesAndRadius();
        this.camera.radius = 6;
        this.camera.useAutoRotationBehavior = true;
        this.camera.autoRotationBehavior.idleRotationSpeed = 0.6;
        this.camera.autoRotationBehavior.idleRotationWaitTime = 10;
        this.camera.autoRotationBehavior.idleRotationSpinupTime = 10;
        this.camera.autoRotationBehavior.zoomStopsAnimation = false;
        this.camera.lowerRadiusLimit = 1;
        this.camera.upperRadiusLimit = 7;
        this.camera.fov = 0.7;
    }

    activateCamera(){
        this.camera.attachControl(document.getElementById("renderCanvas"), true);
        this.camera.useAutoRotationBehavior = false;
        this.camera.rebuildAnglesAndRadius();
        this.camera.radius = 6;
    }

    async createLights(){
        var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.appManager.scene);
        light.intensity = 0.7;
    
        var dirLight = new BABYLON.DirectionalLight('light', new BABYLON.Vector3(0, -1, -0.5), this.appManager.scene);
        dirLight.position = new BABYLON.Vector3(0, 5, -5);
    
        var hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("./content/environment.dds", this.appManager.scene);
        this.appManager.scene.environmentTexture = hdrTexture;
        hdrTexture.gammaSpace = false; 
    
        this.appManager.scene.clearColor = new BABYLON.Color3(0.22, 0.22, 0.2);
        //this.sky = this.appManager.scene.createDefaultSkybox(hdrTexture, true, 100, 0.5);
    }

    focusCameraOnPart(cameraRef, partID){
        for(var i = 0; i<rootMeshes.length;i++){
            if(rootMeshes[i].id.includes(partID)){
                cameraRef.target = newModel.meshes[i].absolutePosition;
                cameraRef.radius = 1;
            }    
        } 
    }
    changeCamera(cameraRef){
        var start = this.appManager.scene.cameras[0].position;
        BABYLON.Animation.CreateAndStartAnimation(
            'camZoom',
            this.appManager.scene.cameras[0], 
            'position', 
            30, 
            60, 
            start, 
            cameraRef._position,
            BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE
        );
    }

    checkForHighlights(parts){
        var product = this.appManager.productManager.importedModel;
        if(parts && parts!="null"){
            product.meshes.forEach(m => {
                this.highlightLayer.addExcludedMesh(m)
                if(m.material != null){
                    m.visibility = 0.1;
                }
            });
            product.meshes.forEach(m => {
                parts.forEach(h => {
                    if(m.id == h){
                        this.highlightLayer.removeExcludedMesh(m)
                        m.visibility = 1;
                        //this.highlightLayer.addMesh(m,BABYLON.Color3.Red());
                    }   
                });
            });
        }else{
            this.highlightLayer.removeAllMeshes();
            product.meshes.forEach(m => {
                this.highlightLayer.removeExcludedMesh(m)
                m.visibility = 1;
            });
        }
    }

    checkForCamera(camera){
        this.checkForCameraTarget();
        var cameras = this.appManager.scene.cameras;
        if(camera || camera!="null"){
            cameras.forEach(c => {
                if(c._parentNode != null){              
                    if(c._parentNode._parentNode.id == camera){
                        this.changeCamera(c._parentNode._parentNode);
                    } 
                }  
            });
        }
    }

    checkForCameraTarget(){
        console.log(this.target)
        if(this.target!="null"){
            this.appManager.productManager.importedModel.meshes.forEach(m => {
                if(m.id == this.target){
                    var origTarget = this.camera.target;
                    var pos = new BABYLON.Vector3(m.absolutePosition.x,m.absolutePosition.y,m.absolutePosition.z) ;

                    BABYLON.Animation.CreateAndStartAnimation(
                        'camTarget',
                        this.appManager.scene.cameras[0], 
                        'target', 
                        50, 
                        30, 
                        origTarget, 
                        new BABYLON.Vector3(m.absolutePosition.x,m.absolutePosition.y,m.absolutePosition.z), 
                        BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE
                    );
                }   
            });
        }
    } 
}