
export class ControlPanel{  
    constructor(uiManager){
        this.uiManager = uiManager;
        this.isLocked = true;
        this.isPowerActive = false;
        this.isPowerLightFlashing = false;
        this.isControlLightFlashing = false;
        this.stepFinished = false;

        this.initialise();
    }

    initialise(){
        var self = this;
        this.powerButton = document.getElementById("powerButton");
        this.controlButton = document.getElementById("controlButton");
        this.greenLight = document.getElementById("greenLight");
        this.redLight = document.getElementById("redLight");
        this.panel = document.getElementById("panel");

        this.powerButton.addEventListener('click',function(event){ 
            self.powerPressed();
        }); 
        this.controlButton.addEventListener('click',function(event){ 
            self.controlPressed();
        }); 
    }

    setState(){
        this.stepFinished = false;
        if(this.isLocked == true){
            this.isPowerActive = false;
            this.isPowerLightFlashing = false;
            this.isControlLightFlashing = false;
            this.greenLight.style.visibility = "visible";
            this.redLight.style.visibility = "hidden";
        }else{
            this.isControlLightFlashing = true;
            this.isPowerLightFlashing = false;
            this.greenLight.style.visibility = "visible";
            this.setControlLightFlashing();
        }
    }   
    powerPressed(){
        if(this.isLocked == true){
            if(this.isPowerActive == false){
                this.isPowerActive = true;
                this.isPowerLightFlashing = true;
                this.setPowerLightFlashing();
                this.startPowerTimer();
                this.uiManager.appManager.audioManager.playSoundByName("beepConstant", true);
            }
        }else if(this.isLocked == false){
            this.uiManager.appManager.playAnimation();
            this.uiManager.appManager.audioManager.stopSounds();
            this.stepFinished = true;
        }  
    }  
    controlPressed(){
        if(this.isLocked == true){
            if(this.isPowerActive == true){
                this.uiManager.appManager.playAnimation();
                this.uiManager.appManager.audioManager.stopSounds();
                this.uiManager.appManager.audioManager.playSoundByName("beepPulse", true);    
                this.stepFinished = true;
            }
        } 
    }    

    setControlLightFlashing(){
        if(this.isControlLightFlashing){
            var that = this;
            if(this.redLight.style.visibility == "visible")
                this.redLight.style.visibility = "hidden";
            else
                this.redLight.style.visibility = "visible";
            setTimeout(function() {
                that.setControlLightFlashing();
            }, 300);
        }
        else
            this.redLight.style.visibility = "hidden"
    }
    setPowerLightFlashing(){
        if(this.isPowerLightFlashing){
            var that = this;
            if(this.greenLight.style.visibility == "visible")
                this.greenLight.style.visibility = "hidden";
            else
                this.greenLight.style.visibility = "visible";
            setTimeout(function() {
                that.setPowerLightFlashing();
            }, 300);
        }
        else
            this.greenLight.style.visibility = "visible";
    }

    startPowerTimer(){
        var self = this;
        setTimeout(function() {
            if(self.stepFinished == false){
                self.isPowerActive = false;
                self.isPowerLightFlashing = false;
                self.uiManager.appManager.audioManager.stopSounds();
            }
        }, 3000);
    }
}