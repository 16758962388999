/* import { XRManager } from "./xrManager.model"; */
import { ProductManager } from "./productManager.js";
import { UIManager } from "./uiManager.js";
import { CameraManager } from './cameraManager.js';
import { AudioManager } from './audioManager.js';

export class AppManager{  
    constructor(scene, engine, dataResult){
        console.log(dataResult);
        this.scene = scene;
        this.engine = engine;
        this.cameraManager = new CameraManager(this, dataResult.targetPart);
        this.productManager = new ProductManager(dataResult, this)
        this.audioManager = new AudioManager(this);
        this.uiManager = new UIManager(this);
        this.sceneActive = false;
        this.currentProcedure = null;
        this.currentStepNumber = 0;
        /* this.xrManager = new XRManager(scene, engine); */
    }

    startScene(){
        document.getElementById("loading").style.display = "none";
        this.sceneActive = true;
        this.cameraManager.activateCamera();
        this.productManager.animationManager.stopAnimation();
    }

    startNewProcedure(procedureName){
        this.currentProcedure = this.productManager.findProcedureByName(procedureName);
        this.uiManager.displayInstructionsScreen();
        this.uiManager.displayStep(this.currentProcedure.steps[this.currentStepNumber]);
    }

    playAnimation(){
        var step = this.currentProcedure.steps[this.currentStepNumber];
        this.productManager.animationManager.playAnimation(step.start, step.end);
        this.uiManager.hideInstructionsScreen();
    }

    startNextStep(){
        this.currentStepNumber++;
        this.uiManager.displayInstructionsScreen();
        var step = this.currentProcedure.steps[this.currentStepNumber];
        this.uiManager.displayStep(step);
        if(step != undefined){
            this.cameraManager.checkForHighlights(step.partsToHighlight);
            this.cameraManager.checkForCamera(step.camera.cameraName);
        }
    }
}