import { Procedure } from "../models/procedure";
import { AnimationManager } from "./animationManager";

export class ProductManager{  
    constructor(data, appManager){
        this.name = data.title;
        this.modelURL = data.modelURL.asset.url;
        this.target = "Hook-1_Motion study - 700-05895-00-1_Motion study - 475-13619-0";
        this.procedures = [];
        for (var i = 0; i < data.procedures.length; i++) {
            this.procedures.push(
                new Procedure(
                    data.procedures[i]
                )
            )
        }
        this.animationManager = null;
        this.importedModel = null;
        this.appManager = appManager;

        this.spawnModel();
    }

    async spawnModel(){
        this.importedModel = await BABYLON.SceneLoader.ImportMeshAsync("", this.modelURL,"",this.scene);
        this.setUpProduct();  
    }

    setUpProduct(){
        this.animationManager = new AnimationManager(this.appManager);
        this.appManager.startScene(); 
 
        var self = this;
        this.importedModel.animationGroups.forEach(function (animationGroup) {
            animationGroup.onAnimationGroupEndObservable.add((point, data) => {
                self.appManager.startNextStep();// display next step
/*                 if(self.appManager.uiManager.uiElements != null){
                    if(self.appManager.uiManager.uiElements.buttonHome.isVisible == true)
                        self.updateCurrentStep();
                        self.appManager.cameraManager.checkForCameraTarget(); 
                }*/
            })
        }); 

        this.appManager.cameraManager.highlightLayer.removeAllMeshes();
        this.importedModel.meshes[0].position = new BABYLON.Vector3(0,0,0);
        this.appManager.cameraManager.checkForCameraTarget();
    }
    
    updateCurrentStep(){
        this.appManager.uiManager.hideControls(); 
        var self = this;
        if(this.currentStep == 0){
            setTimeout(function(){  
                self.appManager.cameraManager.checkForCameraTarget();
            }, 500);
        }
        if(this.procedures[this.currentAnimation].steps.length==1 && this.currentStep == 0){
            this.playAnimation(
                this.procedures[this.currentAnimation].steps[this.currentStep].start,
                this.procedures[this.currentAnimation].steps[this.currentStep].end);
            this.currentStep++;
        }else if(this.currentStep < (this.procedures[this.currentAnimation].steps.length)){
            this.displayNextStep();
        }else if(this.currentStep == (this.procedures[this.currentAnimation].steps.length) && this.currentStep != 1){
            this.appManager.uiManager.hideControls();
            this.appManager.uiManager.uiElements.imageProcedureComplete.isVisible = true;
            this.productAudio.stopSounds();
            this.currentStep++;
        }
    }

    displayNextStep(){
        var currentStep = this.procedures[this.currentAnimation].steps[this.currentStep];
        var inputRequired = currentStep.inputRequired.title;
        var uiElements = this.appManager.uiManager.uiElements;

        if(inputRequired.includes("Joystick")){
            this.appManager.uiManager.displayJoystick(inputRequired)
        }
        else if(inputRequired.includes("Control-Panel")){
            this.appManager.uiManager.displayControlPanel(inputRequired, currentStep.step)
        }
        else if(inputRequired.includes("Confirm-Button")){
            this.appManager.uiManager.uiElements.buttonConfirm.isVisible = true;
        }

        if(inputRequired.includes("Control-Panel") == false){
            uiElements.textStepSubDescription.text = currentStep.stepDescription;
            uiElements.textStepDescription.text = currentStep.stepSubDescription;
        }  

        if(currentStep.tipDescription != "null"){
            uiElements.textTipText.text = currentStep.tipDescription;
            uiElements.textTipText.isVisible = true;           
        } 
        uiElements.textStepDescription.isVisible = true;
        uiElements.textStepSubDescription.isVisible = true;

        this.appManager.cameraManager.checkForCamera();
        this.appManager.cameraManager.checkForHighlights();
    }

    toggleMeshesVisibility(){
        if(this.rootMeshes[0].isVisible){
            this.rootMeshes[0].isVisible = false;
            for (var mesh of this.rootMeshes){
                mesh.setEnabled(false); 
            }  
        }else{
            this.rootMeshes[0].isVisible = true;  
            for (var mesh of this.rootMeshes){
                mesh.setEnabled(true); 
            }
        }  
    }

    findProcedureByName(name){
        var foundProcedure;
        this.procedures.forEach(function (procedure) {
            if(procedure.procedureName == name){
                foundProcedure = procedure
            }
        }); 
        return foundProcedure;
    }
}